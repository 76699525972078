import firebase from "firebase";

export const firebaseConfig = {
  apiKey: "AIzaSyDo3FBO3h8ezgVjLbBAhIvdW-LYStDaXsY",
  authDomain: "tribute-534e5.firebaseapp.com",
  databaseURL: "https://tribute-534e5-default-rtdb.firebaseio.com",
  projectId: "tribute-534e5",
  storageBucket: "tribute-534e5.appspot.com",
  messagingSenderId: "919182458448",
  appId: "1:919182458448:web:3259fa4ed6fe3947391b55",
};

const firebaseDb = firebase.initializeApp(firebaseConfig);
export const fireStorage = firebaseDb.storage().ref();
export default firebaseDb.database().ref();
