import React from "react";
import Header from "../../components/header/header";
import example from "../../assets/pictures/pic19-1a.jpg";
import familytree from "../../assets/images/family tree.jpg";

const Biography = (prop) => {
  const [showDownload, setShowDownload] = React.useState(false);

  React.useEffect(() => {
    setShowDownload(true);
  }, []);

  return (
    <>
      <div className="h-screen relative overflow-scroll">
        <Header black={true} menu={prop.menu} />
        <main className="pt-20 lg:px-20 xl:px-56 2xl:px-96 px-4">
          <div className="lg:px-24">
            <div className="mt-8 mx-auto">
              <h1 className="md:text-xl text-xl  text-gray-900 uppercase font-normal">
                Biography
              </h1>
            </div>
            <div className=" mt-4">
              <img
                src={example}
                alt=""
                className="h-96 w-full object-cover object-center"
              />
              <h2 className="text-left text-lg font-normal mt-4 text-gray-800">
                LIFE AND MEMORIES OF ELLEN C. KWAWUKUME
              </h2>
              <hr />
              <p className="mt-4 text-gray-700 text-justify text-base">
                Ellen lived a simple and faithful life devoted to service and to
                mother Ghana
              </p>

              {showDownload ? (
                <div className="pb-10">
                  <p className="text-justify">
                    There are many facets to my mother, Ellen C. Kwawukume:
                    farmer, self-styled architect, public prosecutor, pro-bono
                    lawyer, builder, counselor, philanthropist and many more
                    hats. After several attempts I have come to the realization
                    that we all experienced her in our own unique way. So I am
                    resigned to pen down a few historical facts, interspersed
                    with my version of her and tidbits from a whole lot of
                    people. Ellen C. Kwawukume was born on the 2 nd of May 1951
                    in Accra. She was the 3 rd child of Mr. Alfred Kwawukume
                    (deceased) and Madam Constancia Akuyovi Vidza (Abuela is
                    frail but fine). Around 1953, thereabout Grandpa Alfred
                    Kwawukume moved to Damango on government business. He was a
                    brilliant accountant I am told. Thus, Ellen started her
                    formal education in far of Damongo. She attended the local
                    LA Primary School from 1955 to 1961. Mama had fond memories
                    of Damango. The sprawling bungalow, foraging for fruits and
                    berries and the terrifying encounters with the local Fulani
                    boys. She said she loved climbing trees and would rather be
                    found reading in a tree than helping out in the kitchen much
                    to the chagrin of grandma Daavi. Shortly after primary
                    school, the family had to relocate to Anloga when grandpa
                    was transferred to Accra, Mama would recall how she and her
                    siblings became known as the” gbegadziviwo” (children of the
                    savannah). They were close-knit and fought their childhood
                    battles as a group. From 1961 to 1965 Ellen was in middle
                    school at LA Middle School, Avete and thereafter proceeded
                    to Anlo Secondary School for her O’Levels. Ellen studied for
                    her A’Levels in Apam Secondary School (1970-1972). She would
                    recount with pride how she passed the University of Ghana
                    Law School selection exams prior to obtaining her A’Levels.
                    In the 70’s Legon was the only law school and the selection
                    process was highly competitive, about 15% of applicants were
                    selected. Ellen was admitted into the University of Ghana in
                    1972 and graduated in 1975 with an LLB. She had a first
                    child, Senanu shortly after graduating. In typical Ellen
                    fashion, she multi-tasked child care and post LLB law school
                    from 1976-1978. Ellen was called to bar in November of 1978.
                  </p>
                  <p className="text-justify mt-2">
                    Ellen started her professional career with the
                    Attorney-General’s Dept. in November 1978 as a national
                    service person. She was hard working and meticulous. On the
                    17 th of September 1979 she was formally employed by the
                    Attorney-General’s Dept. as an Assistant State Attorney.
                    Ellen was devoted to her work and in September of 1980,
                    barely a year after being employed she was promoted to the
                    rank of State Attorney. She managed this feat whiles heavily
                    pregnant with her second child, Senyuiedzorm. Ellen rose
                    through the ranks by dint of hard work and was made the
                    Chief State Attorney in charge of the Volta Region on 1 st
                    of May 1997 a well-deserved 46 th birthday present. As
                    regional head, she served on various committees including
                    the Regional Tender Board. Her contribution towards the
                    development of Ho and the Volta Region at large was
                    significant. Ellen loved Ho and took good advantage of the
                    large tracks of land around the bungalows. Her farm had
                    everything: mangoes, yams, maize, beans, cassava, lettuce,
                    carrots, radish (yes ooh), tomatoes, chicken, ducks, goats,
                    rabbits etc. She was an advocate for organic foods and grows
                    all the food her household needed. Ellen was a long term
                    planner and was very thrift with her earnings. She would say
                    “if you can buy a car and fuel it, then you can build a
                    house. Your daily fuel cost more than one of cement”. She
                    was a self-styled architect and loved to design homes.
                  </p>
                  <p className="text-justify mt-2">
                    Ellen was devoted to her children and invested her time and
                    effort in their education and upbringing. She was quietly
                    very spiritual and her unpretentious nature guided her very
                    action. Ellen devoted her whole professional career to the
                    Attorney-General’s Dept. She was transferred from Ho to head
                    the Ashanti Region in 1997. After 8 years of excellent
                    service to the Asante Region, she was transferred to A-G’s
                    Headquarters in Accra. She was instrumental in the setting
                    up of the “Justice For All Programme”, aimed at decongesting
                    of prisons. Ellen retired in April 2011 after about 33 years
                    of service. Ellen spent her retirement years assisting all
                    who called on her. She continued to mentor and guide young
                    attorneys. She gave expect legal advice freely and was
                    keenly interested in the welfare of her many “children”. Woe
                    unto you if you mention land or building project: She would
                    place an embargo on your non-essential expenditure and help
                    you structure how to achieve it. She dotted on us all and we
                    all called her “Mama”. Ellen passed away on the 25 th of
                    December 2020.
                  </p>

                  <div className=" mt-4">
                    <img
                      src={familytree}
                      alt=""
                      className="h-96 w-full object-cover object-center"
                    />
                  </div>
                </div>
              ) : (
                <p className="mt-5 font-bold">
                  Full Biography will be available on saturday, 30th January,
                  2021 at 7:00 am
                </p>
              )}
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Biography;
