import React from "react";
import { X } from "react-feather";
import fireDb from "../../config/firebase";

const AddTribute = (prop) => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [tribute, setTribute] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const submit = (e) => {
    setLoading(true);
    e.preventDefault();

    if (name.length === 0 || tribute.length === 0) {
      if (name.length === 0) alert("Enter your name");
      else alert("Enter your tribute");
      setLoading(false);
    } else {
      const tributeData = {
        name,
        tribute,
        phone,
        email: email.toLowerCase(),
        validated: false,
        date: new Date().toISOString(),
      };
      fireDb.child("tributes").push(tributeData, (err) => {
        alert("Tribute sent. Thank You");
        setLoading(false);
        window.location.href = "/";
        prop.tribute(false);
        if (err) {
          console.log(err);
        }
      });
    }
  };

  return (
    <div
      className={
        "absolute top-0 left-0 right-0 bottom-0 bg-black h-screen w-screen text-white"
      }
      style={{ zIndex: 300 }}
    >
      <div className="fixed right-8 top-4" onClick={() => prop.tribute(false)}>
        <X />
      </div>

      <div className="h-full w-full flex flex-col justify-start items-center overflow-scroll">
        <div className="lg:w-1/2 px-4">
          <div className="mt-16 mx-auto ">
            <h1 className="md:text-2xl text-2xl text-white font-extrabold uppercase">
              Write a tribute
            </h1>
          </div>
          <form onSubmit={submit} className="mt-4">
            <input
              disabled={loading}
              type="text"
              value={name}
              onChange={(event) => setName(event.target.value)}
              className="appearance-none placeholder-white placeholder-opacity-100 border border-white bg-transparent
                rounded-0 w-full py-3 px-4 text-white leading-5 focus:outline-none focus:ring-2
                 focus:ring-indigo-500 mb-4"
              placeholder="Enter your name"
            />
            <input
              disabled={loading}
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              className="appearance-none placeholder-white placeholder-opacity-100 border border-white bg-transparent
                rounded-0 w-full py-3 px-4 text-white leading-5 focus:outline-none focus:ring-2
                 focus:ring-indigo-500"
              placeholder="Enter your email"
            />
            <p className="mb-4">
              NB: Email address will not be displayed for privacy concerns
            </p>
            <input
              disabled={loading}
              type="text"
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
              className="appearance-none placeholder-white placeholder-opacity-100 border border-white bg-transparent
                rounded-0 w-full py-3 px-4 text-white leading-5 focus:outline-none focus:ring-2
                 focus:ring-indigo-500"
              placeholder="Enter your phone number"
            />
            <p className="mb-4">
              NB: Phone numbers will not be displayed for privacy concerns
            </p>

            <textarea
              disabled={loading}
              value={tribute}
              onChange={(event) => setTribute(event.target.value)}
              rows="12"
              placeholder="Enter tribute"
              className=" mt-2 appearance-none placeholder-white placeholder-opacity-100 border border-white bg-transparent
                rounded-0 w-full py-3 px-4 text-white leading-5 focus:outline-none focus:ring-2
                 focus:ring-indigo-500"
            />
            <button
              disabled={loading}
              type="submit"
              className=" w-full px-4 py-2 border border-transparent rounded-0 shadow-sm text-sm
                font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2
                 focus:ring-indigo-500"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddTribute;
