import React from "react";
import { Menu } from "react-feather";
import { AppContext } from "../../App";

const Header = () => {
  const appContext = React.useContext(AppContext);
  return (
    <header
      className={
        "top-0 left-0 w-screen fixed lg:px-24 xl:px-56 2xl:px-96 px-4 py-8 text-white bg-black"
      }
      style={{ zIndex: 100 }}
    >
      <div className="lg:px-24 flex flex-row justify-between">
        <div className="font-extrabold uppercase">
          <a href="/">Ellen C. Kwawukume</a>
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            appContext.setMenu(true);
          }}
        >
          <Menu />
        </div>
      </div>
    </header>
  );
};

export default Header;
