import React from "react";
import { X } from "react-feather";

const MobileMenu = (prop) => {
  return (
    <div
      className={
        "absolute top-0 left-0 right-0 bottom-0 bg-black h-screen w-screen text-white"
      }
      style={{ zIndex: 1000 }}
    >
      <div className="fixed right-4 top-4" onClick={() => prop.setMenu(false)}>
        <X />
      </div>
      <div className="h-full w-full flex flex-col justify-center items-center">
        <a href="/" className="uppercase text-base hover:underline  -mt-48">
          Home
        </a>
        <a
          href="/biography"
          className="uppercase text-base hover:underline  mt-8"
        >
          Biography
        </a>
        <a
          href="/memories"
          className="uppercase text-base hover:underline  mt-8"
        >
          Memories
        </a>
        <a
          className="uppercase text-base hover:underline  mt-8 cursor-pointer "
          onClick={() => {
            prop.setMenu(false);
            prop.setShowTributes(true);
          }}
        >
          Tributes
        </a>
        <a
          href="/arrangements"
          className="uppercase text-base hover:underline  mt-8"
        >
          Funeral Service
        </a>
        <div
          className="mt-8"
          onClick={() => {
            prop.setMenu(false);
            prop.setAddTribute(true);
          }}
        >
          <h3 className="uppercase text-base hover:underline ">
            Write a tribute
          </h3>
        </div>
        <a
          className="uppercase text-base hover:underline  mt-8 cursor-pointer "
          onClick={() => {
            prop.setMenu(false);
            prop.setShowTributes(false);
            prop.setShowLiveStream(true);
          }}
        >
          Live Stream
        </a>
        <a
          href="/donations"
          className="uppercase text-base hover:underline  mt-8"
        >
          Donations
        </a>
      </div>
    </div>
  );
};

export default MobileMenu;
