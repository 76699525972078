import pic1 from "../../assets/pictures/pic19-1a.jpg";
import pic2 from "../../assets/pictures/pic19-1b.jpg";
import pic3 from "../../assets/pictures/pic20-1a.jpg";
import pic4 from "../../assets/pictures/pic21-1b.jpg";
import pic5 from "../../assets/pictures/pic22-1b.jpg";
import pic6 from "../../assets/pictures/pic20-1b.jpg";
import pic7 from "../../assets/pictures/pic22-1a.jpg";
import pic8 from "../../assets/pictures/pic23-1a.jpg";
import pic9 from "../../assets/pictures/pic24-1a.jpg";
import pic10 from "../../assets/pictures/pic24-1b.jpg";
import pic11 from "../../assets/pictures/pic25-1a.jpg";
import pic12 from "../../assets/pictures/pic25-1b.jpg";
import pic13 from "../../assets/pictures/pic26-1a.jpg";
import pic14 from "../../assets/pictures/pic26-1b.jpg";
import pic15 from "../../assets/pictures/pic27-1a.jpg";
import pic16 from "../../assets/pictures/Scanned Document-1a.jpg";
import pic17 from "../../assets/pictures/Scanned Document-1b.jpg";
import pic18 from "../../assets/pictures/Scanned Document-1c.jpg";
import pic19 from "../../assets/pictures/Scanned Document-1d.jpg";
import pic20 from "../../assets/pictures/site01.jpg";
import pic21 from "../../assets/pictures/site02.jpg";
import pic22 from "../../assets/pictures/site03.jpg";
import pic23 from "../../assets/pictures/site04.jpg";
import pic24 from "../../assets/pictures/site05.jpg";
import pic25 from "../../assets/pictures/site06.jpg";
import pic26 from "../../assets/pictures/site07.jpg";
import pic27 from "../../assets/pictures/site07a.jpg";
import pic28 from "../../assets/pictures/site08.jpg";
import pic29 from "../../assets/pictures/site09.jpg";
import pic30 from "../../assets/pictures/site10.jpg";
import pic31 from "../../assets/pictures/site11.jpg";
import pic32 from "../../assets/pictures/site12.jpg";
import pic33 from "../../assets/pictures/site13.jpg";
import pic34 from "../../assets/pictures/site14.jpg";
import pic35 from "../../assets/pictures/site16.jpg";
import pic36 from "../../assets/pictures/site17.jpg";
import pic37 from "../../assets/pictures/site18.jpg";
import pic38 from "../../assets/pictures/site19.jpg";
import pic39 from "../../assets/pictures/site20.jpg";
import pic40 from "../../assets/pictures/site21.jpg";
import pic41 from "../../assets/pictures/site22.jpg";
import pic42 from "../../assets/pictures/site23.jpg";
import pic43 from "../../assets/pictures/site24.jpg";
import pic44 from "../../assets/pictures/site25.jpg";
import pic45 from "../../assets/pictures/site27.jpg";
import pic46 from "../../assets/pictures/site28.jpg";
import pic47 from "../../assets/pictures/site28a.jpg";
import pic48 from "../../assets/pictures/site29.jpg";
import pic49 from "../../assets/pictures/site30.jpg";
import pic50 from "../../assets/pictures/site30a.jpg";
import pic51 from "../../assets/pictures/site31.jpg";
import pic52 from "../../assets/pictures/site31a.jpg";
import pic53 from "../../assets/pictures/site32.jpg";
import pic54 from "../../assets/pictures/site33.jpg";
import pic55 from "../../assets/pictures/site33a.jpg";
import pic56 from "../../assets/pictures/site33aa.jpg";
import pic57 from "../../assets/pictures/site34.jpg";
import pic58 from "../../assets/pictures/site34a.jpg";
import pic59 from "../../assets/pictures/site34b.jpg";
import pic60 from "../../assets/pictures/site35.jpg";
import pic61 from "../../assets/pictures/site36.jpg";
import pic62 from "../../assets/pictures/site37.jpg";
import pic63 from "../../assets/pictures/pic001.jpg";
import pic64 from "../../assets/pictures/a.JPG";
import pic65 from "../../assets/pictures/b.jpg";
import pic66 from "../../assets/pictures/c.JPG";
import pic67 from "../../assets/pictures/d.JPG";
import pic68 from "../../assets/pictures/e.JPG";
import pic69 from "../../assets/pictures/f.jpg";
import pic70 from "../../assets/pictures/g.jpg";
import pic71 from "../../assets/pictures/h.jpg";
import pic72 from "../../assets/pictures/i.JPG";
import pic73 from "../../assets/pictures/j.JPG";
import pic74 from "../../assets/pictures/k.JPG";
import pic75 from "../../assets/pictures/IMG-20210125-WA0000.jpg";
import pic76 from "../../assets/pictures/IMG-20210125-WA0001.jpg";
import pic77 from "../../assets/pictures/IMG-20210125-WA0002.jpg";
import pic78 from "../../assets/pictures/IMG-20210125-WA0003.jpg";
import pic79 from "../../assets/pictures/IMG-20210125-WA0004.jpg";
import pic80 from "../../assets/pictures/IMG-20210125-WA0005.jpg";
import pic81 from "../../assets/pictures/IMG-20210125-WA0006.jpg";
import pic82 from "../../assets/pictures/IMG-20210125-WA0007.jpg";
import pic83 from "../../assets/pictures/IMG-20210125-WA0008.jpg";
import pic84 from "../../assets/pictures/IMG-20210125-WA0009.jpg";
import pic85 from "../../assets/pictures/IMG-20210125-WA0010.jpg";
import pic86 from "../../assets/pictures/IMG-20210125-WA0011.jpg";
import pic87 from "../../assets/pictures/IMG-20210125-WA0012.jpg";
import pic88 from "../../assets/pictures/IMG-20210125-WA0013.jpg";
import pic89 from "../../assets/pictures/IMG-20210125-WA0014.jpg";
import pic90 from "../../assets/pictures/IMG-20210125-WA0015.jpg";
import pic91 from "../../assets/pictures/IMG-20210125-WA0016.jpg";
import pic92 from "../../assets/pictures/IMG-20210125-WA0017.jpg";
import pic93 from "../../assets/pictures/IMG-20210125-WA0018.jpg";
import pic94 from "../../assets/pictures/IMG-20210125-WA0019.jpg";
import pic95 from "../../assets/pictures/IMG-20210125-WA0020.jpg";

export const photos = [
  {
    src: pic63,
    width: 4,
    height: 3,
  },
  {
    src: pic1,
    width: 4,
    height: 3,
  },
  {
    src: pic2,
    width: 4,
    height: 3,
  },
  {
    src: pic3,
    width: 3,
    height: 4,
  },
  {
    src: pic4,
    width: 3,
    height: 4,
  },
  {
    src: pic5,
    width: 3,
    height: 4,
  },
  {
    src: pic6,
    width: 4,
    height: 3,
  },
  {
    src: pic7,
    width: 4,
    height: 3,
  },
  {
    src: pic9,
    width: 4,
    height: 3,
  },
  {
    src: pic8,
    width: 4,
    height: 3,
  },
  {
    src: pic10,
    width: 4,
    height: 3,
  },
  {
    src: pic11,
    width: 4,
    height: 3,
  },
  {
    src: pic12,
    width: 4,
    height: 3,
  },
  {
    src: pic13,
    width: 4,
    height: 3,
  },
  {
    src: pic14,
    width: 4,
    height: 3,
  },
  {
    src: pic15,
    width: 4,
    height: 3,
  },
  {
    src: pic16,
    width: 3,
    height: 4,
  },
  {
    src: pic17,
    width: 3,
    height: 4,
  },
  {
    src: pic18,
    width: 4,
    height: 3,
  },
  {
    src: pic19,
    width: 3,
    height: 4,
  },
  {
    src: pic20,
    width: 3,
    height: 4,
  },
  {
    src: pic21,
    width: 3,
    height: 4,
  },
  {
    src: pic22,
    width: 3,
    height: 4,
  },
  {
    src: pic23,
    width: 3,
    height: 4,
  },
  {
    src: pic24,
    width: 5,
    height: 3,
  },
  {
    src: pic25,
    width: 3,
    height: 4,
  },
  {
    src: pic27,
    width: 3,
    height: 4,
  },
  {
    src: pic28,
    width: 4,
    height: 3,
  },
  {
    src: pic29,
    width: 4,
    height: 3,
  },
  {
    src: pic26,
    width: 5,
    height: 3,
  },
  {
    src: pic30,
    width: 4,
    height: 3,
  },
  {
    src: pic31,
    width: 4,
    height: 3,
  },
  {
    src: pic32,
    width: 4,
    height: 3,
  },
  {
    src: pic33,
    width: 4,
    height: 3,
  },
  {
    src: pic34,
    width: 4,
    height: 3,
  },
  {
    src: pic35,
    width: 4,
    height: 3,
  },
  {
    src: pic36,
    width: 4,
    height: 3,
  },
  {
    src: pic37,
    width: 4,
    height: 3,
  },
  {
    src: pic38,
    width: 4,
    height: 3,
  },
  {
    src: pic39,
    width: 4,
    height: 3,
  },
  {
    src: pic40,
    width: 4,
    height: 3,
  },
  {
    src: pic41,
    width: 3,
    height: 4,
  },
  {
    src: pic42,
    width: 4,
    height: 3,
  },
  {
    src: pic43,
    width: 4,
    height: 3,
  },
  {
    src: pic44,
    width: 4,
    height: 3,
  },
  {
    src: pic45,
    width: 3,
    height: 4,
  },
  {
    src: pic46,
    width: 4,
    height: 3,
  },
  {
    src: pic47,
    width: 4,
    height: 3,
  },
  {
    src: pic48,
    width: 5,
    height: 4,
  },
  {
    src: pic49,
    width: 3,
    height: 4,
  },
  {
    src: pic50,
    width: 5,
    height: 3,
  },
  {
    src: pic51,
    width: 3,
    height: 4,
  },
  {
    src: pic52,
    width: 4,
    height: 3,
  },
  {
    src: pic53,
    width: 4,
    height: 3,
  },
  {
    src: pic54,
    width: 4,
    height: 3,
  },
  {
    src: pic55,
    width: 3,
    height: 4,
  },
  {
    src: pic56,
    width: 4,
    height: 3,
  },
  {
    src: pic57,
    width: 4,
    height: 3,
  },
  {
    src: pic58,
    width: 3,
    height: 4,
  },
  {
    src: pic59,
    width: 4,
    height: 3,
  },
  {
    src: pic60,
    width: 3,
    height: 4,
  },
  {
    src: pic61,
    width: 4,
    height: 3,
  },
  {
    src: pic62,
    width: 3,
    height: 4,
  },
  {
    src: pic63,
    width: 4,
    height: 4,
  },
  {
    src: pic64,
    width: 4,
    height: 3,
  },
  {
    src: pic65,
    width: 4,
    height: 4,
  },
  {
    src: pic66,
    width: 4,
    height: 4,
  },
  {
    src: pic67,
    width: 4,
    height: 4,
  },
  {
    src: pic68,
    width: 4,
    height: 5,
  },
  {
    src: pic69,
    width: 4,
    height: 4,
  },
  {
    src: pic70,
    width: 4,
    height: 4,
  },
  {
    src: pic71,
    width: 4,
    height: 4,
  },
  {
    src: pic72,
    width: 4,
    height: 4,
  },
  {
    src: pic73,
    width: 3,
    height: 4,
  },
  {
    src: pic74,
    width: 3,
    height: 4,
  },
  {
    src: pic75,
    width: 5,
    height: 3,
  },
  {
    src: pic76,
    width: 3,
    height: 4,
  },
  {
    src: pic77,
    width: 3,
    height: 4,
  },
  {
    src: pic78,
    width: 5,
    height: 3,
  },
  {
    src: pic79,
    width: 4,
    height: 4,
  },
  {
    src: pic80,
    width: 4,
    height: 4,
  },
  {
    src: pic81,
    width: 3,
    height: 4,
  },
  {
    src: pic82,
    width: 3,
    height: 4,
  },
  {
    src: pic83,
    width: 4,
    height: 4,
  },
  {
    src: pic84,
    width: 4,
    height: 5,
  },
  {
    src: pic85,
    width: 3,
    height: 4,
  },
  {
    src: pic86,
    width: 3,
    height: 4,
  },
  {
    src: pic87,
    width: 3,
    height: 4,
  },
  {
    src: pic88,
    width: 3,
    height: 4,
  },
  {
    src: pic89,
    width: 3,
    height: 4,
  },
  {
    src: pic90,
    width: 3,
    height: 4,
  },
  {
    src: pic91,
    width: 3,
    height: 4,
  },
  {
    src: pic92,
    width: 3,
    height: 4,
  },
  {
    src: pic93,
    width: 3,
    height: 4,
  },
  {
    src: pic94,
    width: 3,
    height: 4,
  },
  {
    src: pic95,
    width: 3,
    height: 4,
  },
];
