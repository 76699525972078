import React from "react";
import Header from "../../components/header/header";
import mobilemoney from "../../assets/images/mobilemoney.jpeg";

const Donations = (prop) => {
  return (
    <>
      <div className="h-screen relative overflow-scroll">
        <Header black={true} menu={prop.menu} />
        <main className="pt-20 lg:px-20 xl:px-56 2xl:px-96 px-4">
          <div className="lg:px-24">
            <div className="mt-8 mx-auto">
              <h1 className="md:text-xl text-xl  text-gray-900 uppercase font-normal">
                Donations
              </h1>
            </div>
            <div className=" mt-4">
              <p className="mt-4 text-gray-700 text-justify text-base">
                Donations tables will be available on funeral grounds. Thank you
              </p>
            </div>
            <div className="mt-4 flex items-center">
              <img
                src={mobilemoney}
                alt="mobile moneny logo"
                className="mr-3"
                style={{
                  width: 120,
                  height: 80,
                }}
              />
              <p>
                Donation via mobile money can be made to this number:{" "}
                <span className="font-bold text-lg">
                  0244363001 - Senanu Agottah
                </span>
              </p>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Donations;
