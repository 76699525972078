import React from "react";
import Header from "../../components/header/header";
import seatingArrangements from "../../assets/images/Clergy.png";

const Arrangements = (prop) => {
  const [showDownload, setShowDownload] = React.useState(false);

  React.useEffect(() => {
    setShowDownload(true);
  }, []);

  return (
    <>
      <div className="h-screen relative overflow-scroll">
        <Header black={true} menu={prop.menu} />
        <main className="pt-24 lg:px-20 xl:px-56 2xl:px-96 px-4">
          <div className="lg:px-24">
            <div className="mt-8 mx-auto text-left">
              <h1 className="md:text-xl text-lg text-gray-900 uppercase font-normal">
                Directions
              </h1>
            </div>
            <div className="mt-4">
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1226.4807185532813!2d-0.1978314!3d5.6797824!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf9c4e518a6893%3A0x169da65e4177f585!2zNcKwNDAnNDkuMCJOIDDCsDExJzUzLjYiVw!5e1!3m2!1sen!2sgh!4v1610111889728!5m2!1sen!2sgh"
                  className="w-full"
                  height="450"
                  frameBorder="0"
                  style={{ border: 10 }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                />
              </div>
            </div>
            <div className="mt-8 mx-auto text-left">
              <h1 className="md:text-xl text-lg text-gray-900 uppercase font-normal">
                Programme
              </h1>
            </div>
            <div>
              <div className="w-full mx-auto">
                <div className="bg-white shadow-md rounded my-6">
                  <table className="text-left w-full border-collapse">
                    <thead className="bg-black text-white">
                      <tr>
                        <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">
                          Event
                        </th>
                        <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">
                          Time (30th January 2021)
                        </th>
                        <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">
                          Location
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="hover:bg-grey-lighter">
                        <td className="py-4 px-6 border-b border-grey-light">
                          Filling Past
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          7:00am - 8:00am
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          Residence in Haatso
                        </td>
                      </tr>
                      <tr className="hover:bg-grey-lighter">
                        <td className="py-4 px-6 border-b border-grey-light">
                          Funeral Service
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          8:30 - 10:30am
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          Residence in Haatso
                        </td>
                      </tr>
                      <tr className="hover:bg-grey-lighter">
                        <td className="py-4 px-6 border-b border-grey-light">
                          Interment
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          Late Afternoon
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          Hayibor Kwawukume Family Cemetry Anloga, VR.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="text-left w-full border-collapse">
                    <thead className="bg-black text-white">
                      <tr>
                        <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">
                          Event
                        </th>
                        <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">
                          Time (31th January 2021)
                        </th>
                        <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">
                          Location
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="hover:bg-grey-lighter">
                        <td className="py-4 px-6 border-b border-grey-light">
                          Final Funeral Rites
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          2:00pm - 5:00pm
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          Residence in Haatso
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="mt-8 mx-auto text-left">
              <h1 className="md:text-xl text-lg text-gray-900 uppercase font-normal">
                Order of Service (Saturday, 30th January, 2021)
              </h1>
              <h1 className="md:text-xl text-lg text-gray-900 uppercase font-normal mt-4 underline">
                Part 1: Funeral Service
              </h1>
            </div>
            <div>
              <div className="w-full mx-auto">
                <div className="bg-white shadow-md rounded my-6">
                  <table className="text-left w-full border-collapse">
                    <tbody>
                      <tr className="hover:bg-grey-lighter">
                        <td className="py-4 px-6 border-b border-grey-light">
                          Call to Worship
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          Pr. Joseph Afeku
                        </td>
                      </tr>
                      <tr className="hover:bg-grey-lighter">
                        <td className="py-4 px-6 border-b border-grey-light">
                          Opening Hymn
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          E.P Hymn 272:1-2
                        </td>
                      </tr>
                      <tr className="hover:bg-grey-lighter">
                        <td className="py-4 px-6 border-b border-grey-light">
                          Opening Prayer
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          Pr. Joseph Afeku
                        </td>
                      </tr>
                      <tr className="hover:bg-grey-lighter">
                        <td className="py-4 px-6 border-b border-grey-light">
                          Welcome Address
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          Mr. Elorm Segbefia
                        </td>
                      </tr>

                      <tr className="hover:bg-grey-lighter">
                        <td className="py-4 px-6 border-b border-grey-light">
                          Song Ministration
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          Achimota E.P Church Choir
                        </td>
                      </tr>

                      <tr className="hover:bg-grey-lighter">
                        <td className="py-4 px-6 border-b border-grey-light">
                          Reading of Biography
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          Mrs. Millicent Addae-Mensah
                        </td>
                      </tr>
                      <tr className="hover:bg-grey-lighter">
                        <td className="py-4 px-6 border-b border-grey-light">
                          Reading of Selected Tributes
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          Children, Attorney-General's Department
                        </td>
                      </tr>
                      <tr className="hover:bg-grey-lighter">
                        <td className="py-4 px-6 border-b border-grey-light">
                          Hymn
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          Methodist Hymn 878
                        </td>
                      </tr>
                      <tr className="hover:bg-grey-lighter">
                        <td className="py-4 px-6 border-b border-grey-light">
                          Moot Court
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          Attorney-General's Department
                        </td>
                      </tr>
                      <tr className="hover:bg-grey-lighter">
                        <td className="py-4 px-6 border-b border-grey-light">
                          Scripture Reading Sermon
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          Mr. Bernard Malm
                        </td>
                      </tr>

                      <tr className="hover:bg-grey-lighter">
                        <td className="py-4 px-6 border-b border-grey-light">
                          Offertory
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          -
                        </td>
                      </tr>
                      <tr className="hover:bg-grey-lighter">
                        <td className="py-4 px-6 border-b border-grey-light">
                          Presentation of Wreaths
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          Pr. Joseph Afeku
                        </td>
                      </tr>
                      <tr className="hover:bg-grey-lighter">
                        <td className="py-4 px-6 border-b border-grey-light">
                          Dedication of Offertory
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          Pr. Clement Kadogbe
                        </td>
                      </tr>

                      <tr className="hover:bg-grey-lighter">
                        <td className="py-4 px-6 border-b border-grey-light">
                          Announcement and Recognition
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          Mr. Elorm Segbefia
                        </td>
                      </tr>
                      <tr className="hover:bg-grey-lighter">
                        <td className="py-4 px-6 border-b border-grey-light">
                          Vote of Thanks
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          Miss. Demi Nutsugah
                        </td>
                      </tr>
                      <tr className="hover:bg-grey-lighter">
                        <td className="py-4 px-6 border-b border-grey-light">
                          Closing Prayer and Benediction
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          Pastor Emmanuel Owusu
                        </td>
                      </tr>
                      <tr className="hover:bg-grey-lighter">
                        <td className="py-4 px-6 border-b border-grey-light">
                          Closing Hymn
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          E.P Hymn 257
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <h1 className="md:text-xl text-lg text-gray-900 uppercase font-normal mt-4 underline">
                    Part 2: Interment
                  </h1>
                  <table className="text-left w-full border-collapse">
                    <tbody>
                      <tr className="hover:bg-grey-lighter">
                        <td className="py-4 px-6 border-b border-grey-light">
                          Opening Prayer
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          Mr. Bernard Malm
                        </td>
                      </tr>
                      <tr className="hover:bg-grey-lighter">
                        <td className="py-4 px-6 border-b border-grey-light">
                          Filing Past
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          Family Hall, Anloga
                        </td>
                      </tr>
                      <tr className="hover:bg-grey-lighter">
                        <td className="py-4 px-6 border-b border-grey-light">
                          Hymn
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          E.P Hymn 274
                        </td>
                      </tr>
                      <tr className="hover:bg-grey-lighter">
                        <td className="py-4 px-6 border-b border-grey-light">
                          Burial Service
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          Hayibor-Kwawukume Family Cemetary
                        </td>
                      </tr>
                      <tr className="hover:bg-grey-lighter">
                        <td className="py-4 px-6 border-b border-grey-light">
                          Parting Hymn
                        </td>
                        <td className="py-4 px-6 border-b border-grey-light">
                          E.P Hymn 591
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="mt-8 mx-auto text-left">
              <h1 className="md:text-xl text-lg text-gray-900 uppercase font-normal">
                Seating Arrangement
              </h1>
            </div>
            <div className="mt-4">
              <img
                src={seatingArrangements}
                alt="seating arrangements"
                className="w-full object-cover h-full"
              />
            </div>
            <div className="mt-8 mx-auto text-left">
              <h1 className="md:text-xl text-lg text-gray-900 uppercase font-normal">
                Brochure
              </h1>
            </div>
            <div className="mt-4 mb-10">
              {showDownload ? (
                <a
                  target={"_blank"}
                  href="https://drive.google.com/file/d/1pEVNmIgUepwjcCpdeTVu-RJwGsm5eyBH/view?usp=sharing"
                  className="mx-1 px-4 py-2 border border-transparent rounded-0 shadow-sm text-sm
                font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2
                 focus:ring-green-500"
                >
                  Download Brochure
                </a>
              ) : (
                <p className="font-bold">
                  Funeral brochure will be available for download on saturday,
                  30th January, 2021 at 7:00 am
                </p>
              )}
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Arrangements;
