import React from "react";
import Home from "./pages/home/home";
import { Switch, Route } from "react-router-dom";
import Memories from "./pages/memories/Memories";
import Biography from "./pages/biography/biography";
import AddTribute from "./components/addTribute";
import MobileMenu from "./components/mobileMenu";
import TributeList from "./components/tributes";
import Arrangements from "./pages/arrangements";
import { X } from "react-feather";
import ApproveTributes from "./pages/approve-tributes";
import Cover from "./assets/images/cover3.jpg";
import Donations from "./pages/donations";

export const AppContext = React.createContext({});

function App() {
  const [menu, setMenu] = React.useState(false);
  const [addTribute, setAddTribute] = React.useState(false);
  const [showTributes, setShowTributes] = React.useState(false);
  const [liveStream, setLiveStream] = React.useState(false);
  return (
    <>
      {menu && (
        <MobileMenu
          setMenu={setMenu}
          setAddTribute={setAddTribute}
          setShowTributes={setShowTributes}
          setShowLiveStream={setLiveStream}
        />
      )}
      {addTribute && <AddTribute tribute={setAddTribute} />}
      {showTributes && <TributeList tribute={setShowTributes} />}
      {liveStream && (
        <div
          className={
            "absolute top-0 left-0 right-0 bottom-0 bg-black h-screen w-screen text-white"
          }
          style={{ zIndex: 300 }}
        >
          <div
            className="fixed right-8 top-4"
            onClick={() => setLiveStream(false)}
          >
            <X />
          </div>

          <div className="h-full w-full flex flex-col justify-center items-center overflow-scroll">
            <iframe
              className="w-full lg:w-1/2"
              height="400"
              src="https://www.youtube.com/embed/JVZvTJ_Cpoc"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      )}

      <AppContext.Provider
        value={{
          setMenu,
          setAddTribute,
          setShowTributes,
          setLiveStream,
        }}
      >
        <Switch>
          <Route exact path="/memories">
            <Memories menu={setMenu} tribute={setShowTributes} />
          </Route>
          <Route exact path="/biography">
            <Biography menu={setMenu} tribute={setShowTributes} />
          </Route>
          <Route exact path="/arrangements">
            <Arrangements menu={setMenu} tribute={setShowTributes} />
          </Route>
          <Route exact path="/approvetributes">
            <ApproveTributes />
          </Route>
          <Route exact path="/donations">
            <Donations />
          </Route>
          <Route path="/">
            <Home
              menu={setMenu}
              tribute={setAddTribute}
              tributeList={setShowTributes}
            />
          </Route>
        </Switch>
      </AppContext.Provider>
    </>
  );
}

export default App;
