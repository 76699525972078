import React from "react";
import example from "../../assets/images/pic2.png";
import { Menu } from "react-feather";
import bgSound from "../../assets/audio/bg.mp3";
import { AppContext } from "../../App";

const Home = (prop) => {
  const [audio] = React.useState(new Audio(bgSound));
  const appContext = React.useContext(AppContext);

  React.useEffect(() => {
    if (window.document && window.document.readyState === "complete") {
      audio.load();
      audio.loop = true;
    }
  }, [audio]);
  return (
    <>
      <div className="h-screen text-black flex flex-col justify-between relative bg-white">
        <header className="flex justify-end p-6 block md:hidden">
          <div
            className="text-right text-black"
            onClick={() => prop.menu(true)}
          >
            <Menu />
          </div>
        </header>

        <main
          className="flex-1 flex justify-center flex-col"
          onMouseMove={() => audio.play()}
        >
          <div className=" md:w-1/2 lg:w-1/4 text-center px-4 md:p-0 mx-auto">
            <div className="bg-white">
              <img
                src={example}
                alt=""
                className="h-full w-full object-cover object-center mx-auto my-auto"
              />
            </div>
            <div className="lg:text-5xl text-4xl font-bold uppercase text-gray-900">
              Ellen C. Kwawukume
            </div>
            <div className="lg:mt-6 mt-3 text-lg font-light text-gray-900">
              1951-2020
            </div>
            <div className="lg:mt-8 mt-4 text-lg font-light text-gray-900">
              "Do not cry, for i know there is a better place for all that die
              believing in Christ"
            </div>
          </div>
        </main>
        <footer>
          <div className="py-10 text-center hidden md:block text-gray-900">
            <a
              href="/biography"
              className="mr-6 uppercase font-bold text-sm hover:underline"
            >
              Biography
            </a>
            <a
              href="/memories"
              className="mr-6 uppercase font-bold text-sm hover:underline"
            >
              Memories
            </a>
            <a
              className="mr-6 uppercase font-bold text-sm hover:underline cursor-pointer"
              onClick={() => {
                appContext.setShowTributes(true);
              }}
            >
              Tributes
            </a>
            <a
              href="/arrangements"
              className="mr-6 uppercase font-bold text-sm hover:underline"
            >
              Funeral Service
            </a>
            <a
              onClick={() => prop.tribute(true)}
              href="#"
              className="mr-6 uppercase font-bold text-sm hover:underline"
            >
              Write a tribute
            </a>
            <a
              className=" uppercase font-bold text-sm hover:underline cursor-pointer"
              onClick={() => {
                appContext.setLiveStream(true);
              }}
            >
              Live Stream
            </a>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Home;
