import React from "react";
import fireDb from "../../config/firebase";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const ApproveTributes = () => {
  const [tributes, setTributes] = React.useState({});
  const [edit, setEdit] = React.useState("none");
  const [newTribute, setNewTribute] = React.useState("");
  const [tributesStateText, setTributesStateText] = React.useState(
    "loading...."
  );
  React.useEffect(() => {
    fireDb.child("tributes").on("value", (snapshot) => {
      if (snapshot.val()) {
        setTributes(snapshot.val());
      }
      setTributesStateText("No Tributes");
    });
  }, []);

  return (
    <div
      className="mx-auto bg-white h-screen w-screen md:w-full
        lg:w-2/3   text-black flex flex-col"
      style={{ zIndex: 300 }}
    >
      <div className="text-center uppercase font-bold py-5 bg-gray-100 px-8">
        <h1>Tributes</h1>
      </div>
      <hr className="text-green-400" />

      <DragDropContext
        onDragEnd={(results) => {
          console.log(results);
          // const sourceTribute = tributes[results.draggableId];
          // const destinationTribute =
          //   tributes[Object.keys(tributes)[results.destination.index]];
          // const sourceIndex = results.draggableId;
          //
          // console.log(Object.keys(tributes)[results.destination.index]);
          // console.log(
          //   tributes[Object.keys(tributes)[results.destination.index]]
          // );
        }}
      >
        <Droppable droppableId="tributes">
          {(provided) => (
            <div
              id="tributes"
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="mt-3 flex-1 overflow-scroll px-8"
            >
              {Object.keys(tributes).length === 0 && (
                <div>{tributesStateText}</div>
              )}
              {Object.keys(tributes).map((key, index) => {
                return (
                  <Draggable key={key} draggableId={key} index={index}>
                    {(provided) => (
                      <div
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                        className="mb-4 text-left"
                      >
                        {edit === key ? (
                          <div>
                            <textarea
                              rows={5}
                              className="w-full border p-4"
                              onChange={(e) => setNewTribute(e.target.value)}
                            >
                              {tributes[key].tribute}
                            </textarea>
                          </div>
                        ) : (
                          <div>
                            <div className="flex flex-row justify-between align-center">
                              <h2 className="text-gray-700 uppercase text-xs">
                                {tributes[key].name}
                              </h2>
                              <div className="flex flex-row align-center">
                                <h2 className="text-gray-700 uppercase text-xs mr-2">
                                  {tributes[key].phone ?? ""}
                                </h2>
                                <h2 className="text-gray-700 text-xs">
                                  {tributes[key].email ?? ""}
                                </h2>
                              </div>
                            </div>

                            <div className="text-left">
                              <p className="mt-2 mb-4 text-gray-900 text-base text-left">
                                {tributes[key].tribute}
                              </p>
                            </div>
                          </div>
                        )}

                        <div className="w-full mb-4">
                          {!tributes[key].validated && (
                            <button
                              onClick={() => {
                                let updates = {};
                                updates["/tributes/" + key] = {
                                  ...tributes[key],
                                  validated: true,
                                };
                                fireDb
                                  .update(updates)
                                  .then((data) => console.log(data))
                                  .catch((err) => console.log(err));
                              }}
                              type="submit"
                              className="mx-1 px-4 py-2 border border-transparent rounded-0 shadow-sm text-sm
                font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2
                 focus:ring-green-500"
                            >
                              Approve
                            </button>
                          )}
                          {edit === key ? (
                            <>
                              <button
                                onClick={() => {
                                  let updates = {};
                                  updates["/tributes/" + key] = {
                                    ...tributes[key],
                                    tribute: newTribute,
                                  };
                                  fireDb
                                    .update(updates)
                                    .then((data) => setEdit("none"))
                                    .catch((err) => {
                                      alert(err);
                                      setEdit("none");
                                    });
                                }}
                                className="mx-1 px-4 py-2 border border-transparent rounded-0 shadow-sm text-sm
                font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2
                 focus:ring-green-500"
                              >
                                Save
                              </button>
                              <button
                                onClick={() => {
                                  setEdit("none");
                                }}
                                className="mx-1 px-4 py-2 border border-transparent rounded-0 shadow-sm text-sm
                font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2
                 focus:ring-gray-500"
                              >
                                Cancel
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                onClick={() => {
                                  setEdit(key);
                                }}
                                className="mx-1 px-4 py-2 border border-transparent rounded-0 shadow-sm text-sm
                font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2
                 focus:ring-green-500"
                              >
                                Edit
                              </button>
                            </>
                          )}
                          <button
                            onClick={() => {
                              let updates = {};
                              updates["/tributes/" + key] = null;
                              fireDb
                                .update(updates)
                                .then((data) => console.log(data))
                                .catch((err) => console.log(err));
                            }}
                            className="mx-1  px-4 py-2 border border-transparent rounded-0 shadow-sm text-sm
                font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2
                 focus:ring-red-500"
                          >
                            Delete
                          </button>
                        </div>
                        <hr />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default ApproveTributes;
