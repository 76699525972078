import React from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import Header from "../../components/header/header";
import { photos } from "./pictures";

const Memories = (prop) => {
  const [currentImage, setCurrentImage] = React.useState(0);
  const [viewerIsOpen, setViewerIsOpen] = React.useState(false);

  const openLightbox = React.useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <>
      <div className="z-100">
        <Header black={false} menu={prop.menu} />
        <main
          className="pt-24 lg:px-20 xl:px-56 2xl:px-96 px-4 h-screen overflow-scroll"
          style={{ zIndex: 50 }}
        >
          <div className="lg:px-20">
            <>
              <Gallery
                photos={photos}
                direction={"row"}
                onClick={openLightbox}
              />
              <ModalGateway>
                {viewerIsOpen ? (
                  <Modal onClose={closeLightbox}>
                    <Carousel
                      currentIndex={currentImage}
                      views={photos.map((x) => ({
                        ...x,
                        srcset: x.srcSet,
                        caption: x.title,
                      }))}
                    />
                  </Modal>
                ) : null}
              </ModalGateway>
            </>
          </div>
        </main>
      </div>
    </>
  );
};

export default Memories;
