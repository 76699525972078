import React from "react";
import { X } from "react-feather";
import fireDb from "../../config/firebase";

const TributeList = (prop) => {
  const [tributes, setTributes] = React.useState({});
  const [tributesStateText, setTributesStateText] = React.useState(
    "loading...."
  );
  React.useEffect(() => {
    fireDb.child("tributes").on("value", (snapshot) => {
      if (snapshot.val()) {
        const tri = snapshot.val();
        Object.keys(tri).map((key) => {
          if (tri[key].validated) {
            setTributes((prevState) => {
              return { ...prevState, [key]: tri[key] };
            });
          }
          return {};
        });
      }
      setTributesStateText("No Tributes");
    });
  }, []);

  return (
    <div
      className="absolute top-0 right-0 bottom-0 bg-white h-screen w-screen md:w-1/2
        lg:w-1/3 xl:w-1/4 2xl:w-1/5 text-black flex flex-col"
      style={{ zIndex: 300 }}
    >
      <div
        className="fixed right-8 top-4 cursor-pointer"
        onClick={() => prop.tribute(false)}
      >
        <X />
      </div>
      <div className="text-left uppercase font-bold py-5 bg-gray-100 px-8">
        <h1>Tributes</h1>
      </div>
      <hr className="text-green-400" />

      <div id="tributes" className="mt-3 flex-1 overflow-scroll px-8">
        {Object.keys(tributes).length === 0 && <div>{tributesStateText}</div>}
        {Object.keys(tributes).map((key) => {
          return (
            <div key={key} className="mb-4 text-left">
              <h2 className="text-gray-700 uppercase text-xs">
                {tributes[key].name}
              </h2>
              <div className="text-left">
                <p className="mt-2 mb-4 text-gray-900 text-base text-left">
                  {tributes[key].tribute}
                </p>
              </div>
              <hr />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TributeList;
